import React from "react"
import footerStyles from "./footer.module.scss"
import Logo from "../images/iconlogo-01-blue.svg"
import FacebookB from "../images/facebookBlue.svg"
import TwitterB from "../images/twitterBlue.svg"
import IgB from "../images/igBlue.svg"

export default function Footer() {
  return (
    <div className={footerStyles.footerContainer}>
      <img className={footerStyles.logo} src={Logo} alt="logo" />
      <div className={footerStyles.rightsContainer}>
        <div className={footerStyles.socialButtonsContainer}>
          <ul className={footerStyles.socialButtonsUl}>
            <a
              href="https://www.facebook.com/uniomx/"
              target="_blank"
              rel="noopener noreferrer"
              title="Unio's facebook"
              className={footerStyles.socialLink}
            >
              <img
                className={footerStyles.socialButton}
                src={FacebookB}
                alt="facebook logo"
              />
            </a>
            <a
              href="https://twitter.com/MxUnio/"
              target="_blank"
              rel="noopener noreferrer"
              title="Unio's twitter"
              className={footerStyles.socialLink}
            >
              <img
                className={footerStyles.socialButton}
                src={TwitterB}
                alt="Twitter logo"
              />
            </a>
            <a
              href="https://www.instagram.com/unio_mx/"
              target="_blank"
              rel="noopener noreferrer"
              title="Unio's Instagram"
              className={footerStyles.socialLink}
            >
              <img
                className={footerStyles.socialButton}
                src={IgB}
                alt="Instagram logo"
              />
            </a>
          </ul>
        </div>
        Todos los derechos Reservados
      </div>
    </div>
  )
}

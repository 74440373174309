import React, { useState, useRef, useEffect } from "react"
import headerStyles from "./header.module.scss"
import LogoWhite from "../images/iconlogo-01-white.svg"
import LogoBlue from "../images/iconlogo-01-blue.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import FacebookB from "../images/facebookBlue.svg"
import TwitterB from "../images/twitterBlue.svg"
import IgB from "../images/igBlue.svg"
import FacebookW from "../images/facebookWhite.svg"
import TwitterW from "../images/twitterWhite.svg"
import IgW from "../images/igWhite.svg"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Fade from "@material-ui/core/Fade"

export default function Header() {
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [uiColor, setUiColor] = useState("#FFF")
  const [barColor, setBarColor] = useState("#0f7cff")
  const [url, setUrl] = useState("")
  const [motionChanged, setMotionChanged] = useState(false)
  const latestUrl = useRef(url)
  const prevUrl = usePrevious(url)

  function lookUrl() {
    setUrl(window.location.pathname)
    if (url !== prevUrl) {
      changeInitialUI()
    }
  }

  function changeInitialUI() {
    if (url === "/") {
      setUiColor("#FFF")
      setBarColor("#0f7cff")
    } else {
      setUiColor("#0f7cff")
      setBarColor("#FFF")
    }
  }

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  useEffect(() => {
    // Used to look at the latest url and the set the color correctly
    latestUrl.current = url
    lookUrl()
  })

  useScrollPosition(({ prevPos, currPos }) => {
    var x = prevPos.y - currPos.y
    if (motionChanged === false) {
      setMotionChanged(true)
    }
    if (
      (currPos.y < -717 && uiColor === "#FFF") ||
      (url !== "/" && uiColor === "#FFF")
    ) {
      setUiColor("#0f7cff")
      setBarColor("#FFF")
    } else if (currPos.y > -717 && uiColor === "#0f7cff" && url === "/") {
      setUiColor("#FFF")
      setBarColor("#0f7cff")
    }

    if (currPos.y < -100) {
      if (x > 5 && hideOnScroll === true) {
        setHideOnScroll(false)
      } else if (x < -10 && hideOnScroll === false) {
        setHideOnScroll(true)
      }
    } else if (hideOnScroll === false) {
      setHideOnScroll(true)
    }
  })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <div
      className={
        hideOnScroll ? headerStyles.headerShow : headerStyles.headerHide
      }
      style={{ backgroundColor: barColor }}
    >
      <div className={headerStyles.logoContainer}>
        <AniLink
          paintDrip
          hex="#0F7FFF"
          className={headerStyles.logoLink}
          to="/"
          title="home"
        >
          <img
            src={uiColor === "#FFF" ? LogoWhite : LogoBlue}
            alt="Unio-logo"
            className={headerStyles.logo}
          />
        </AniLink>
      </div>
      <nav className={headerStyles.navContainer}>
        <ul className={headerStyles.navUl}>
          <Link
            className={headerStyles.link}
            activeClassName={headerStyles.activeLink}
            partiallyActive={true}
            title="Qué es Unio"
            to="/#queEsUnio"
          >
            <li
              className={headerStyles.navItem}
              style={{ color: uiColor }}
              key="queEsUnio"
            >
              ¿QUÉ ES UNIO?
            </li>
          </Link>
          <Link
            className={headerStyles.link}
            activeClassName={headerStyles.activeLink}
            partiallyActive={true}
            title="paquetes"
            to="/#paquetes"
          >
            <li
              className={headerStyles.navItem}
              style={{ color: uiColor }}
              key="paquetes"
            >
              PAQUETES
            </li>
          </Link>
          <AniLink
            className={headerStyles.link}
            paintDrip
            hex="#FFF"
            title="blog"
            to="/blog"
            activeClassName={headerStyles.activeLink}
          >
            <li
              className={headerStyles.navItem}
              style={{ color: uiColor }}
              key="blog"
            >
              BLOG
            </li>
          </AniLink>
          <AniLink
            className={headerStyles.link}
            paintDrip
            hex="#FFF"
            title="Aviso de privacidad"
            to="/privacidad"
            activeClassName={headerStyles.activeLink}
          >
            <li
              className={headerStyles.navItem}
              style={{ color: uiColor }}
              key="privacidad"
            >
              AVISO DE PRIVACIDAD
            </li>
          </AniLink>
          <Link
            className={headerStyles.link}
            activeClassName={headerStyles.activeLink}
            partiallyActive={true}
            title="contacto"
            to="/#contacto"
          >
            <li
              className={headerStyles.navItem}
              style={{ color: uiColor }}
              key="contacto"
            >
              CONTACTO
            </li>
          </Link>
        </ul>
        <Button
          className={headerStyles.linkButton}
          style={{ color: uiColor }}
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          Menú
        </Button>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <Link
              className={headerStyles.link}
              activeClassName={headerStyles.activeLink}
              partiallyActive={true}
              title="Qué es Unio"
              to="/#queEsUnio"
            >
              <li
                className={headerStyles.navItem}
                style={{ color: uiColor }}
                key="queEsUnio"
              >
                ¿QUÉ ES UNIO?
              </li>
            </Link>
          </MenuItem>
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <Link
              className={headerStyles.link}
              activeClassName={headerStyles.activeLink}
              partiallyActive={true}
              title="paquetes"
              to="/#paquetes"
            >
              <li
                className={headerStyles.navItem}
                style={{ color: uiColor }}
                key="paquetes"
              >
                PAQUETES
              </li>
            </Link>
          </MenuItem>
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <AniLink
              className={headerStyles.link}
              paintDrip
              hex="#FFF"
              title="blog"
              to="/blog"
              activeClassName={headerStyles.activeLink}
            >
              <li
                className={headerStyles.navItem}
                style={{ color: uiColor }}
                key="blog"
              >
                BLOG
              </li>
            </AniLink>
          </MenuItem>
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <AniLink
              className={headerStyles.link}
              paintDrip
              hex="#FFF"
              title="Aviso de Privacidad"
              to="/privacidad"
              activeClassName={headerStyles.activeLink}
            >
              <li
                className={headerStyles.navItem}
                style={{ color: uiColor }}
                key="blog"
              >
                AVISO DE PRIVACIDAD
              </li>
            </AniLink>
          </MenuItem>
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <Link
              className={headerStyles.link}
              activeClassName={headerStyles.activeLink}
              partiallyActive={true}
              title="contacto"
              to="/#contacto"
            >
              <li
                className={headerStyles.navItem}
                style={{ color: uiColor }}
                key="contacto"
              >
                CONTACTO
              </li>
            </Link>
          </MenuItem>
        </Menu>
      </nav>
      <div className={headerStyles.socialButtonsContainer}>
        <ul className={headerStyles.socialButtonsUl}>
          <a
            href="https://www.facebook.com/uniomx/"
            target="_blank"
            rel="noopener noreferrer"
            title="Link to Unios's facebook"
            className={headerStyles.socialLink}
          >
            <img
              className={headerStyles.socialButton}
              src={uiColor === "#FFF" ? FacebookW : FacebookB}
              alt="facebook logo"
            />
          </a>
          <a
            href="https://twitter.com/MxUnio"
            target="_blank"
            rel="noopener noreferrer"
            title="Link to Unios's twitter"
            className={headerStyles.socialLink}
          >
            <img
              className={headerStyles.socialButton}
              src={uiColor === "#FFF" ? TwitterW : TwitterB}
              alt="Twitter logo"
            />
          </a>
          <a
            href="https://www.instagram.com/unio_mx/"
            target="_blank"
            rel="noopener noreferrer"
            title="Link to Unios's Instagram"
            className={headerStyles.socialLink}
          >
            <img
              className={headerStyles.socialButton}
              src={uiColor === "#FFF" ? IgW : IgB}
              alt="Instagram logo"
            />
          </a>
        </ul>
      </div>
    </div>
  )
}
